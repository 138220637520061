/**
 * 公共接口API
 */

import $https from '@/utils/request';

/**
 * 创建API枚举
 * 统一管理API
 */

enum API {
    place = '/api/fang/getQueryHeadLine', // 区域、地铁列表
    filter = '/api/fang/getQueryFilter', // 筛选条件
    villageSearch = '/api/fang/queryVillageList', // 小区模糊查询
    villageFilterHousing = '/api/fang/queryFangByVillageId', // 房源筛选（根据小区ID获取房源）
    brokerSearch = '/api/fang/queryBrokerKList', // 经纪人模糊查询
    passengerSerach = '/api/fang/queryKeYuanList', // 客源人模糊查询
    areaSreach = '/api/fang/areaInfo/queryList', // 区域模糊查询
    circleSreach = '/api/fang/businessCircle/queryList', // 商圈模糊查询
    keyStoreSearch = '/api/fang/queryStoreList', // 门店模糊查询
    labelQuery = '/api/fang/queryHouseLabelList', // 标签查询（室内标签，列表标签，房屋设施）
    dictQuery = '/api/dDict/getDictValueList', // 通用字典查询（例如：等级，类型等）
    leasedRented = '/api/fang/reportRented', // 上报已租
    lookContactWay = '/api/fang/fangLookContactInfo', // 查看联系方式
    lockHousingAndPassenger = '/api/fang/lock', // 锁定房源和客源
    headlineSearch = '/api/fangTitle/queryFangTitleList', // 房源标题查询
    trailTotal = '/api/look/viewFyKyLookCount', // 跟进总数
    followData = '/api/fyKy/phoneLookRecord', // 跟进状态
}

/**
 * 获取区域、地铁列表
 */
export const getPlace = () => $https.get(API.place);

/**
 * 获取筛选条件（房源管理、客源管理）
 * @param params 参数对象
 *  @value filterType  筛选类型（10：房源管理，20：客源管理）
 */
export const getFilter = (params: any) => $https.get(API.filter, { params });

/**
 * 小区模糊查询
 * @param params 参数对象
 *  @value name  关键字
 */
export const getVillageSearch = (params: any) => $https.get(API.villageSearch, { params });

/**
 * 房源筛选（根据小区ID获取房源）
 * @param params 参数
 *  @value villageId  小区ID
 */
export const getVillageFilterHousing = (params: any) => $https.get(API.villageFilterHousing + `/${params}`);

/**
 * 经纪人模糊查询
 * @param params 参数对象
 *  @value name  关键字
 */
export const getBrokerSearch = (params: any) => $https.get(API.brokerSearch, { params });

/**
 * 客源人模糊查询
 * @param params 参数对象
 *  @value name  关键字
 */
export const getPassengerSerach = (params: any) => $https.get(API.passengerSerach, { params });

/**
 * 区域模糊查询
 * @param params 参数对象
 *  @value name  关键字
 */
export const getAreaSreach = (params: any) => $https.get(API.areaSreach, { params });

/**
 * 商圈模糊查询
 * @param params 参数对象
 *  @value name  关键字
 */
export const getCircleSreach = (params: any) => $https.post(API.circleSreach, params);

/**
 * 门店模糊查询
 * @param params 参数对象
 *  @value name  关键字
 */
export const getKeyStoreSearch = (params: any) => $https.get(API.keyStoreSearch, { params });

/**
 * 标签查询（室内标签，列表标签，房屋设施）
 */
export const getLabelQuery = () => $https.get(API.labelQuery);

/**
 * 通用字典查询（例如：等级，类型等）
 * @param params 参数对象
 *  @value keyName 名称（多个keyName用逗号分隔）
 */
export const getDictQuery = (params: any) => $https.get(API.dictQuery, { params });

/**
 * 上报已租（房源、客源）
 * @param params 参数对象
 *  @value operationType  操作类型（1：房源，2：客源）
 *  @value fyId  房源ID
 *  @value keYuanId  客源ID
 *  @value employeeId  经纪人ID
 *  @value dealPrice  成交价格
 *  @value remark   备注
 */
export const leasedRented = (params: any) => $https.post(API.leasedRented, params);

/**
 * 查看联系方式（fyId/kyId只同时存在一个）
 * @param params 参数对象
 *  @value fyId	房源ID
 *  @value kyId	客源ID
 *  @value remark 备注
 */
export const lookContactWay = (params: any) => $https.post(API.lookContactWay, params);

/**
 * 锁定房源和客源
 * @param params 参数对象
 *  @value relationId	房源id/客源id
 *  @value relationType	关联类型 10:房源 20:客源
 *  @value permissionType 权限类型 10:短期锁定 20:长期锁定
 */
export const lockHousingAndPassenger = (params: any) => $https.post(API.lockHousingAndPassenger, params);

/**
 * 房源标题查询
 * @param params 参数对象
 *  @value name  关键字
 */
export const getHeadlineSearch = (params: any) => $https.get(API.headlineSearch, { params });

/**
 * 跟进总数
 * @param params 参数对象
 *  @value countType 统计类型 10:房源数据 20:客源数据
 */
export const getTrailTotal = (params: any) => $https.post(API.trailTotal, params);

/**
 * 跟进状态
 */
export const getFollowData = () => $https.get(API.followData);