<template>
    <el-dialog v-model="show" title="查看联系方式" :lock-scroll="false" width="30%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" draggable>
        <template v-if="list.length">
            <li class="uy-p-b-10 uy-font-18" v-for="(item, index) in list" :key="index">{{ item || '' }}</li>
        </template>
        <el-form ref="formDataRef" :model="formData.data" :rules="formData.rules">
            <el-form-item :prop="item.key" v-for="(item, index) in formData.columns" :key="index">
                <el-input type="textarea" v-model="formData.data[item.key]" :placeholder="item.tips" :maxlength="100"></el-input>
                <div style="margin: 0 0 0 auto;">{{ `${formData.data[item.key]?.length}/100` }}</div>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button class="uy-w-100 uy-h-40" type="primary" @click="onClickAffirm(formDataRef)">确定</el-button>
        </template>
    </el-dialog>
</template>

<!-- 查看联系方式弹窗 -->
<script setup lang="ts">
import { debounce } from 'lodash';
import { ElNotification, type FormInstance } from 'element-plus';
import { ref, reactive, watch } from 'vue';
import { lookContactWay } from '@/api/common';

/**
 * 组件通信
 * @param data （房源/客源）信息
 * @param type 类型（房源/客源）
 *  @value 房源：'housing'
 *  @value 客源：'passenger'
 */
 const props = defineProps({
    data: {
        type: Object,
        default: () => ({}),
        required: true
    },
    type: {
        type: String,
        default: '',
        required: true
    }
})

// 组件方法
const $emit = defineEmits(['init'])

// 数据
let list = reactive<any[]>([]);
const formDataRef = ref<FormInstance>(); // 查看联系方式表单引用
let show = ref(false);
let formData = reactive<any>({
    data: {
        fyId: null, // 房源ID
        kyId: null, // 客源ID
        remark: '', // 查看备注
    },
    columns: [
        { key: 'remark', name: '查看备注', tips: '请输入查看联系方式备注' }
    ],
    rules: {
        remark: [
            { required: true, message: '请输入查看备注', trigger: 'blur' }
        ]
    }
})

// 方法
const onClickAffirm = debounce(async (formEl?: FormInstance | undefined) => {
    if (!formEl) {
        const res: any = await lookContactWay(formData.data);
        switch(res.code) {
            case 0:
                list.length = 0;
                Object.assign(list, res.data.ownerPhoneList);
                show.value = true;
                break;
            default:
                break;
        }
        return
    }
    await formEl.validate(async (valid) => {
        if (valid) {
            const res: any = await lookContactWay(formData.data);
            switch(res.code) {
                case 0:
                    ElNotification({
                        message: `跟进成功`,
                        type: 'success'
                    });
                    props.data['isLookPhoneNumber'] = 20;
                    $emit('init')
                    if(props.data.lookPhoneNum > 0) {
                        props.data.lookPhoneNum -= 1;
                    }
                    resetForm(formDataRef.value)
                    break;
                default:
                    break;
            }
        }
    })
}, 1000);

const resetForm = (formEl: FormInstance | undefined) => {
if (!formEl) return
formEl.resetFields()
}; // 重置表单

const open = () => {
    if(props.data.lookPhoneNum <= 0) return ElNotification({
        message: '已超出查看次数',
        type: 'warning'
    });
    if(props.data.isLookPhoneNumber !== 10) {
        onClickAffirm()
    } else {
        show.value = true;
    }
}; // 打开窗口

// 监听事件
watch(() => props.data, (val) => {
    if(props.type === 'housing') {
        formData.data['fyId'] = val.id || val.fyId;
    }
    if(props.type === 'passenger') {
        formData.data['kyId'] = val.kyId;
    }
    if(props.data.isLookPhoneNumber === 10) {
        show.value = true;
    }
    if(props.data.isLookPhoneNumber === 20) {
        show.value = false;
    }
}, { deep: true });

watch(() => props.data.ownerPhoneList, (val) => {
    if(val.length) {
        list.length = 0;
        Object.assign(list, val)
    }
}, { deep: true })

defineExpose({
    open
});
</script>

<style lang="scss" scoped>
:deep(.el-textarea__inner) {
    height: 120px;
}
</style>